import { Link,useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Footer from "./inc/Footer";
import Header from "./inc/Header";
import EventCalendar from './EventCalendar';
import { API_BASE_URL } from './data/constants';
import axios from 'axios';
function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [userdata, setUserdata] = useState(); 
  let user = sessionStorage.getItem('user');
  let access_token = sessionStorage.getItem('access_token');

 //Use STate For Fetch data
useEffect(() => {
  axios.get(API_BASE_URL+'base/get_profile', {
    params: {
      id: user
    },
    headers: {
      Authorization: 'Bearer ' + access_token
    }
  })
  .then(function (response) {
  //   console.log(response);
    setUserdata(response.data.data);
    setIsLoading(false);
  })
  .catch(function (error) {
    console.log(error);
    setIsLoading(false);
  })
  .finally(function () {
    // always executed
    setIsLoading(false);
  });
}, [access_token, user]);
//End UseState

  if (isLoading) {
    return  <div className="loader"></div>;
  }


  
  return (
    <>
    {/* Main Wrapper */}
    <div className="main-wrapper">
     <Header />
     {/* Page Wrapper */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Welcome {userdata.name} !</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-4 menu_col">
            <Link to={'/'}>
              <div className="card dash-widget menu_box">
                <div className="card-body">
                 <center>
                    <i className="fa fa-home menu_icon" />
                    <div className="menu_title">Home</div>
                  </center>
                 
                </div>
              </div>
              </Link>
            </div>
            <div className="col-4 menu_col">
             <Link to={'/todo-list'}>
              <div className="card dash-widget menu_box">
                <div className="card-body">
                 <center>
                    <i className="fa fa-edit menu_icon" />
                    <div className="menu_title">Todo List</div>
                  </center>
                </div>
              </div>
              </Link>

            </div>
          
            <div className="col-4 menu_col">
            <Link to={'/events'}>
              <div className="card dash-widget menu_box">
                <div className="card-body">
                 <center>
                    <i className="fa fa-calendar menu_icon" />
                    <div className="menu_title">Events</div>
                  </center>
                 
                </div>
              </div>
              </Link>
            </div>
           <div className='col-sm-12'>
           <EventCalendar />
           </div>
            
          </div>
      
    
        </div>
        {/* /Page Content */}
      </div>
      {/* /Page Wrapper */}
    </div>
    {/* /Main Wrapper */}

    <Footer page="home"/>

    </>
  );
}

export default Home;

import React , { useEffect, useRef } from 'react'
import { Link , useNavigate  } from 'react-router-dom';

export default function Footer(props) {
  const navigate = useNavigate();

  return (
    <>
     {/* /Footer Menu */}
     <div className="bottom-fixed-menu text-center">
        <ul>
            <li><Link to={'/'} className={(props.page == 'home')?'active' : ''}><span className="fa fa-home" aria-hidden="true" /><br />Home </Link></li>
            <li><Link to={'/todo-list'} className={(props.page == 'todolist')?'active' : ''}><span className="fa fa-check" aria-hidden="true" /><br />Todolist</Link>
            </li>
            <li><Link to={'/events'} className={(props.page == 'events')?'active' : ''}><span className="fa fa-calendar" aria-hidden="true" /><br />Events</Link>
            </li>
            <li><Link to={'/profile'} className={(props.page == 'profile')?'active' : ''}><span className="fa fa-user" aria-hidden="true" /><br />Profile</Link>
            </li>
            <li  onClick={() => navigate(-1)} ><a ><span className="fa fa-arrow-left" aria-hidden="true"/><br />Back</a></li>
        </ul>
    </div>

    </>
  )
}

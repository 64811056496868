import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './Home';
import Error404 from './pages/Error404';
import Todo from './pages/Todo';
import Login from './pages/Login';
import Event from './pages/Event';
import Profile from './pages/Profile';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));

let allRoutes = createBrowserRouter(
  [
    {
      path : '/', 
      element : <Home/>
    },
    {
      path : '*', // FOR 404 Not Found
      element : <Error404/>
    },
    {
      path : 'todo-list',
      element : <Todo/>
    },
    {
      path : 'events',
      element : <Event/>
    },
    {
      path : 'profile',
      element : <Profile/>
    },
    {
      path : 'login',
      element : <Login/>
    }
  ]
)
root.render(
  <React.StrictMode>
    <RouterProvider router={allRoutes} />
  </React.StrictMode>
);

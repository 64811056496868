import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../inc/Header';
import Footer from '../inc/Footer';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../data/constants';
import axios from 'axios';


export default function Event() {
  const [all_events, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addtaskclass, setAddtaskclass] = useState(false);
  const [formData, setFormData] = useState({type : '',date:'',name : '',mobile : ''});
  const [editEvent, setEditData] = useState({type : '',date:'',name : '',mobile : '',id:'',index : '',event_status : ''});

  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission status
  const [today, setTodayData] = useState([]);
  const [past, setPastData] = useState([]);
  const [upcoming, setUpcomingData] = useState([]);
  const [modalTriggered, setModal] = useState(false); 


  let getValue = (event) =>{
    let oldData = {...formData}
    let inputName = event.target.name;
    let inputValue = event.target.value;
    oldData[inputName] = inputValue;
    setFormData(oldData);
   
}
//getValue2
let getValue2 = (event) =>{
  let oldData = {...editEvent}
  let inputName = event.target.name;
  let inputValue = event.target.value;
  oldData[inputName] = inputValue;
  setEditData(oldData);
 
}
  let user = sessionStorage.getItem('user');
  let access_token = sessionStorage.getItem('access_token');

  //Use STate For Fetch data
  useEffect(() => {
    axios.get(API_BASE_URL+'base/events', {
      params: {
        user_id: user
      },
      headers: {
        Authorization: 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response);
      setData(response.data);
      setIsLoading(false);
    })
    .catch(function (error) {
      console.log(error);
      setIsLoading(false);
    })
    .finally(function () {
      // always executed
      setIsLoading(false);
    });
  }, [access_token, user]);
  //End UseState

//   Filter  Events

useEffect(() => {
    let today_event = all_events.filter((v) => v.event_status == 'today');
    let past_event = all_events.filter((v) => v.event_status == 'past');
    let upcoming_event = all_events.filter((v) => v.event_status == 'upcoming');
    setTodayData(today_event);
    setPastData(past_event);
    setUpcomingData(upcoming_event);
  }, [all_events]);
// End

  if (isLoading) {
    return  <div className="loader"></div>;
  }
  
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // addTask
  let addTask = ()=>{
    setAddtaskclass(!addtaskclass)
  }
  //End

  //insert event Start
  let insertEvent = ()=>{
    if(formData.name == "" || formData.mobile == "" || formData.type == "" )
    {
      toast.error('Please Fill Required Fields');
      return false
    }
    setIsSubmitting(true); // Disable button
    axios.post(API_BASE_URL+'base/add_event', {
      headers: {
        Authorization: 'Bearer ' + access_token
      },
      name : formData.name,
      date : formData.date,
      mobile : formData.mobile,
      type : formData.type,
      user_id : user
    })
    .then(function (response) {
      console.log(response.data);
      if(response.data.status == "failed")
      {
        toast.error('Failed ! Try Again');
        setIsSubmitting(false); // Re-enable button
      }
      else
      {
        toast.success('Todo Added');
        setIsSubmitting(false); // Re-enable button
        
        if(response.data.data.event_status == "today")
        {
            setTodayData([...today, response.data.data]) // add row in state data
        }
        else if(response.data.data.event_status == "past")
        {
            setPastData([...past, response.data.data]) // add row in state data
        }
        else if(response.data.data.event_status == "upcoming")
        {
            setUpcomingData([...upcoming, response.data.data]) // add row in state data
        }
        setFormData({type : '',date:'',name : '',mobile : ''})
        
        console.log(all_events)

      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error('Failed ! Try Again');
    });
  }
  //insert event end
  const openWhatsApp = (phoneNumber,type) => {
    let whatsappUrl;
    if(type == 1)
    {
         whatsappUrl = `https://wa.me/${phoneNumber}`;
    }
    else
    {
         whatsappUrl = `tel:${phoneNumber}`;
    }
    window.open(whatsappUrl, '_blank');
};

   //delete event start
   let deleteEvent = (id,index,event_status) =>{
    axios.get(API_BASE_URL+'base/delete_event', {
      params: {
        id: id
      },
      headers: {
        Authorization: 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      if(response.data.status == "success")
      {
        let after_filter; 
        if(event_status == "today")
        {
            after_filter = today.filter((v,i) => i != index);
            setTodayData(after_filter); 
        }
        else if(event_status == "past")
        {
            after_filter = past.filter((v,i) => i != index);
            setPastData(after_filter); 
        }
        else if(event_status == "upcoming")
        {
            after_filter = upcoming.filter((v,i) => i != index);
            setUpcomingData(after_filter); 
        }
        toast('Event Deleted!');
      }
      else{
        toast('Failed');
      }
    })
    .catch(function (error) {
      console.log(error);
      toast('Failed');
    });
    
}
//end delete

//edit todo

let edit_event = (id) =>{
    let edit_data = all_events.filter((v,i) => v.id == id)[0];
    let index = all_events.map((v, i) => (v.id == id ? i : null)).filter(v => v !== null);
    let oldData = {
                    type : edit_data.type,
                    date : edit_data.date,
                    name : edit_data.name,
                    mobile : edit_data.mobile,
                    id : id ,
                    index : index
                }
    setEditData(oldData);
    console.log(edit_data);
    setModal(true)
  }
  let close_modal =() =>{
    setModal(false)
  }
//   updateEvent
let handleSubmit = (e)=>{
  e.preventDefault();
  if(editEvent.name == "" || editEvent.type == "" || editEvent.date == "" )
    {
      toast.error('All Field Required');
      return false
    }
    setIsSubmitting(true); // Disable button
    axios.post(API_BASE_URL+'base/update_event', {
      headers: {
        Authorization: 'Bearer ' + access_token
      },
      name : editEvent.name,
      date : editEvent.date,
      mobile : editEvent.mobile,
      type : editEvent.type,
      id : editEvent.id
    })
    .then(function (response) {
      console.log(response.data);
      if(response.data.status == "failed")
      {
        toast.error('Failed ! Try Again');
        setIsSubmitting(false); // Re-enable button
      }
      else
      {
        toast.success('Event Updated');
        setIsSubmitting(false); // Re-enable button
        let newArray = [...all_events]; 
        newArray[editEvent.index] =  response.data.data;  
        setData(newArray);
        // Filter Data
        let today_event = all_events.filter((v) => v.event_status == 'today');
        let past_event = all_events.filter((v) => v.event_status == 'past');
        let upcoming_event = all_events.filter((v) => v.event_status == 'upcoming');
        setTodayData(today_event);
        setPastData(past_event);
        setUpcomingData(upcoming_event);
        //ENd
        setEditData({ type : '',date : '', name : '',mobile : '', id : '' ,  index : '' });
        setModal(false)
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error('Failed ! Try Again');
    });
  }

  //update  end
  return (
    <>
     <ToastContainer />
      <Header />
      <div className="page-wrapper">
      <div >
        <div style={{ display: modalTriggered ? 'block' : 'none',position: 'absolute', zIndex: '999', width: '100%' }}>
          <div className="modal-dialog" style={{marginTop:'20px'}}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Event</h5>
              </div>
              <form onSubmit={handleSubmit}>
              <div className="modal-body">
                  <input type="hidden" name="id" value={editEvent.id} />
                  <input type="hidden" name="index" value={editEvent.index} />
                  <label htmlFor="type">Type*</label>
                  <select name="type" id="type" className='form-control' value={editEvent.type} onChange={getValue2}  required>
                      <option value="">--SELECT--</option>
                      <option value="Birthday">Birthday</option>
                      <option value="Anniversary">Anniversary</option>
                      <option value="Other">Other</option>
                  </select>
                  <label htmlFor="Date">Date*</label>
                  <input type="date" name="date" value={editEvent.date} className='form-control'  onChange={getValue2} required/>
                  <label htmlFor="name">Name*</label>
                  <input type="text" name="name" value={editEvent.name}  className='form-control'  onChange={getValue2} required/>
                  <label htmlFor="Date">Contact No</label>
                  <input type="number" name="mobile" value={editEvent.mobile}  className='form-control'  onChange={getValue2} />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={close_modal}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>{isSubmitting?'Updating..':'Update'}
                </button>
              </div>
              </form>

            </div>
          </div>
        </div>
      </div>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">
                  <Link to={'/'}>Dashboard </Link>/ 
                  <span className="breadcrumb-item active">Events</span>
                  <button className='add-task-btn btn btn-white btn-info btn-sm float-right'  onClick={addTask}>Add Event</button>
                </h3>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="chat-box">
            <div className="task-wrapper">
              <div className="task-list-container">
                <div className="task-list-body">
                <div className="task-list-footer">
                  <div className={`new-task-wrapper ${addtaskclass?'visible' :''}`}  style={{overflow: 'hidden'}}>
                    <form>
                        <div className="row">
                            <div className="col-6" style={{paddingRight:'5px'}}>
                                <label htmlFor="type">Type*</label>
                                <select name="type" id="type" className='form-control' value={formData.type} onChange={getValue} >
                                    <option value="">--SELECT--</option>
                                    <option value="Birthday">Birthday</option>
                                    <option value="Anniversary">Anniversary</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="col-6" style={{paddingLeft:'5px'}}>
                                <label htmlFor="Date">Date*</label>
                                <input type="date" name="date" value={formData.date} className='form-control'  onChange={getValue} />
                            </div>
                            <div className="col-6"  style={{paddingRight:'5px'}}>
                                <label htmlFor="name">Name*</label>
                                <input type="text" name="name" value={formData.name}  className='form-control'  onChange={getValue} />
                            </div>
                            <div className="col-6" style={{paddingLeft:'5px'}}>
                                <label htmlFor="Date">Contact No</label>
                                <input type="number" name="mobile" value={formData.mobile}  className='form-control'  onChange={getValue} />
                            </div>
                        </div>
                        <br/>
                    <span className="add-new-task-btn btn" id="add-task" onClick={insertEvent}  disabled={isSubmitting}>{isSubmitting?'Loading..':'Add Event'}</span>
                    <span className="btn" id="close-task-panel"  onClick={addTask}>Close</span>
                    </form>
                  </div>
                </div>
                <div className="card">
                    <div className="card-body1" style={{padding:'8px'}}>
                        <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified" style={{border:'none',background :'#f7f7f7',padding:'0px',borderRadius :'20px'}}>
                        <li className="nav-item"><a className="nav-link active" href="#solid-rounded-justified-tab1" data-toggle="tab">Today</a></li>
                        <li className="nav-item"><a className="nav-link" href="#solid-rounded-justified-tab2" data-toggle="tab">Past</a></li>
                        <li className="nav-item"><a className="nav-link" href="#solid-rounded-justified-tab3" data-toggle="tab">Upcoming</a></li>
                        </ul>
                        <div className="tab-content">
                        <div className="tab-pane show active" id="solid-rounded-justified-tab1">
                            <ul className="contact-list">
                            {today.map((row, i) => (
                                <li key={i}>
                                    <div className="contact-cont">
                                        <div className="float-left user-img">
                                            <a href="#" className="avatar">
                                                <img className="rounded-circle" alt src="assets/img/event.png" />
                                            </a>
                                        </div>
                                        <div className="contact-info">
                                        <span className="contact-name text-ellipsis" onClick={() => openWhatsApp(row.mobile,1)}><i className="fa fa-whatsapp" style={{fontSize:'16px'}}></i>&nbsp;<b style={{textTransform : 'uppercase',color : 'black'}}>{row.name}</b> | {row.mobile} </span>
                                        <span className="contact-date">{row.type} | {row.date}</span>
                                        </div>
                                        <ul className="contact-action">
                                        <li className="dropdown dropdown-action">
                                            <a href className="dropdown-toggle action-icon" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#edit_contact" onClick={() => openWhatsApp(row.mobile,0)}>Call</a>
                                            <a className="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#edit_contact"  onClick={() => edit_event(row.id)}>Edit</a>
                                            <a className="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#delete_contact" onClick={() => {if(window.confirm('Are you sure ?'))deleteEvent(row.id,i,row.event_status)}}>Delete</a>
                                            </div>
                                        </li>
                                        </ul>
                                    </div>
                            </li>
                            ))}
                            </ul>
                            {(today.length == 0)?
                                 <h3 className='text-center'><br/>No Data Available...<br/><br/></h3>
                                : ""}
                        </div>
                        <div className="tab-pane" id="solid-rounded-justified-tab2">
                        <ul className="contact-list">
                            {past.map((row, i) => (
                                <li key={i}>
                                    <div className="contact-cont">
                                        <div className="float-left user-img">
                                            <a href="#" className="avatar">
                                                <img className="rounded-circle" alt src="assets/img/event.png" />
                                            </a>
                                        </div>
                                        <div className="contact-info">
                                        <span className="contact-name text-ellipsis" onClick={() => openWhatsApp(row.mobile,1)}><i className="fa fa-whatsapp" style={{fontSize:'16px'}}></i>&nbsp;<b style={{textTransform : 'uppercase',color : 'black'}}>{row.name}</b> | {row.mobile} </span>
                                        <span className="contact-date">{row.type} | {row.date}</span>
                                        </div>
                                        <ul className="contact-action">
                                        <li className="dropdown dropdown-action">
                                            <a href className="dropdown-toggle action-icon" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#edit_contact" onClick={() => openWhatsApp(row.mobile,0)}>Call</a>
                                            <a className="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#edit_contact"  onClick={() => edit_event(row.id)}>Edit</a>
                                            <a className="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#delete_contact" onClick={() => {if(window.confirm('Are you sure ?'))deleteEvent(row.id,i,row.event_status)}}>Delete</a>
                                            </div>
                                        </li>
                                        </ul>
                                    </div>
                            </li>
                            ))}
                            </ul>
                            {(past.length == 0)?
                                 <h3 className='text-center'><br/>No Data Available...<br/><br/></h3>
                                : ""}
                        </div>
                        <div className="tab-pane" id="solid-rounded-justified-tab3">
                        <ul className="contact-list">
                            {upcoming.map((row, i) => (
                                <li key={i}>
                                    <div className="contact-cont">
                                        <div className="float-left user-img">
                                            <a href="#" className="avatar">
                                                <img className="rounded-circle" alt src="assets/img/event.png" />
                                            </a>
                                        </div>
                                        <div className="contact-info">
                                        <span className="contact-name text-ellipsis" onClick={() => openWhatsApp(row.mobile,1)}><i className="fa fa-whatsapp" style={{fontSize:'16px'}}></i>&nbsp;<b style={{textTransform : 'uppercase',color : 'black'}}>{row.name}</b> | {row.mobile} </span>
                                        <span className="contact-date">{row.type} | {row.date}</span>
                                        </div>
                                        <ul className="contact-action">
                                        <li className="dropdown dropdown-action">
                                            <a href className="dropdown-toggle action-icon" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#edit_contact" onClick={() => openWhatsApp(row.mobile,0)}>Call</a>
                                            <a className="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#edit_contact"  onClick={() => edit_event(row.id)}>Edit</a>
                                            <a className="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#delete_contact" onClick={() => {if(window.confirm('Are you sure ?'))deleteEvent(row.id,i,row.event_status)}}>Delete</a>
                                            </div>
                                        </li>
                                        </ul>
                                    </div>
                            </li>
                            ))}
                            </ul>
                            {(upcoming.length == 0)?
                                 <h3 className='text-center'><br/>No Data Available...<br/><br/></h3>
                                : ""}
                        </div>
                        </div>
                    </div>
                    </div>
                   
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer page="events" />
    </>
  );
}

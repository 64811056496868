import React, { useState, useEffect } from 'react';
import { Link  } from 'react-router-dom';
import WithAuth from '../WithAuth';
import { API_BASE_URL } from '../data/constants';
import axios from 'axios';

 function Header() {
  const [notification, setNotification] = useState(0); 
  let user = sessionStorage.getItem('user');
  let access_token = sessionStorage.getItem('access_token');

  //Use STate For Fetch data
  useEffect(() => {
    axios.get(API_BASE_URL+'base/get_notification', {
      params: {
        user_id: user
      },
      headers: {
        Authorization: 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      setNotification(response.data.counter);
    })
    .catch(function (error) {
      console.log(error);
    })
   
  }, [access_token, user]);
  //End UseState

  const logout = () => {
    // Remove access token and any other related session data
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('isLoggedIn');
    // Redirect to the login page or any other desired route
    window.location.href = '/login';
  };

  return (
    <>
 {/* Header */}
 <div className="header" style={{background : '#025fc3'}}>
        {/* Logo */}
      
        <div className="header-left" hidden>
          <a href="index.html" className="logo" >
            <img src="assets/img/logo.png" width={40} height={40} alt />
          </a>
        </div>
        {/* /Logo */}
        <a id="toggle_btn" href="javascript:void(0);" >
          <span className="bar-icon" hidden>
            <span />
            <span />
            <span />
          </span>
        </a>
        {/* Header Title */}
        <div className="page-title-box">
          <h3></h3>
        </div>
        {/* /Header Title */}
        <a id="" className="mobile_btn" href="#sidebar"><i className="fa fa-home" /></a>
        <h3 style={{color :' #fff',padding : '18px',marginLeft : "40px"}}>Task Manager</h3>
        {/* Header Menu */}
        <ul className="nav user-menu">
        
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        
        <div className="dropdown mobile-user-menu" style={{width : '250px',whiteSpace :'nowrap'}}>
            <div>
            <Link to={'/events'}> <i className="fa fa-bell-o" style={{color : '#fff'}} /><span className="badge badge-pill bg-danger bellicon" style={{color : '#fff'}}>{notification}</span> </Link>&nbsp;&nbsp;&nbsp;&nbsp;
              <Link  onClick={logout}><i className='fa fa-sign-out' style={{color : '#fff'}}></i></Link>
              
            </div>

        </div>
        {/* /Mobile Menu */}
      </div>
      {/* /Header */}
      {/* Sidebar */}
      <div className="sidebar" id="sidebar" style={{background : '#001920'}}>
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <div className='text-center'>
              {/* <img src="logo.png" alt="" /> */}
              <h3 style={{color:'#fff'}}>My App</h3>
            </div>
            <ul>
              <li className="menu-title"> 
                <span>Main</span>
              </li>
              <li>
              <Link to={'/'}><i className="la la-dashboard" /> <span> Dashboard</span></Link>
              </li>
              <li className="submenu">
                <a href="#"><i className="la la-cube" /> <span> Apps</span> <span className="menu-arrow" /></a>
                <ul style={{display: 'none'}}>
                  <li><a href="chat.html">Chat</a></li>
                  <li className="submenu">
                    <a href="#"><span> Calls</span> <span className="menu-arrow" /></a>
                    <ul style={{display: 'none'}}>
                      <li><a href="voice-call.html">Voice Call</a></li>
                      <li><a href="video-call.html">Video Call</a></li>
                      <li><a href="outgoing-call.html">Outgoing Call</a></li>
                      <li><a href="incoming-call.html">Incoming Call</a></li>
                    </ul>
                  </li>
                  <li><a href="events.html">Calendar</a></li>
                  <li><a href="contacts.html">Contacts</a></li>
                  <li><a href="inbox.html">Email</a></li>
                  <li><a href="file-manager.html">File Manager</a></li>
                </ul>
              </li>
              <li className="menu-title"> 
                <span>Employees</span>
              </li>
             
            </ul>
          </div>
        </div>
      </div>
      {/* /Sidebar */}

    </>
  )
}
export default WithAuth(Header);
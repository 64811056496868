import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../inc/Header';
import Footer from '../inc/Footer';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../data/constants';
import axios from 'axios';

function Profile() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission status
  const [modalTriggered, setModal] = useState(false); 
  const [modalTriggered2, setModal2] = useState(false); 
  const [password, setPassword] = useState({password :'',confirm_password : ''}); 
  const [userdata, setUserdata] = useState(); 
  const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        photo: null
    });
  let user = sessionStorage.getItem('user');
  let access_token = sessionStorage.getItem('access_token');

//Use STate For Fetch data
useEffect(() => {
    axios.get(API_BASE_URL+'base/get_profile', {
      params: {
        id: user
      },
      headers: {
        Authorization: 'Bearer ' + access_token
      }
    })
    .then(function (response) {
    //   console.log(response);
      setUserdata(response.data.data);
      setIsLoading(false);
      setFormData({
        email : response.data.data.email,
        mobile : response.data.data.mobile,
        name : response.data.data.name,
        photo : response.data.data.photo_url
      })
    })
    .catch(function (error) {
      console.log(error);
      setIsLoading(false);
    })
    .finally(function () {
      // always executed
      setIsLoading(false);
    });
  }, [access_token, user]);
  //End UseState


  if (isLoading) {
    return  <div className="loader"></div>;
  }

  let close_modal =() =>{
    setModal(false)
  }
  let close_modal2 =() =>{
    setModal2(false)
  }
  let getValue = (data) =>{
    let oldData = {...password}
    let inputName = data.target.name;
    let inputValue = data.target.value;
    oldData[inputName] = inputValue;
    setPassword(oldData);
  }
  const getValue2 = (e) => {
    const { name, value, files } = e.target;
    if (name === 'photo') {
        setFormData({
            ...formData,
            photo: files[0]
        });
    } else {
        setFormData({
            ...formData,
            [name]: value
        });
    }
};
  //   updateEvent
let handleSubmit = (e)=>{
    e.preventDefault();
    console.log(password)
    if(password.password == "" || password.confirm_password == "")
    {
        toast.error('Please Fill Required Fields');
        return false
    }
    else
    {
        if(password.password == password.confirm_password)
        {
            setIsSubmitting(true); // Disable button
            axios.post(API_BASE_URL+'base/update_password', {
            headers: {
                Authorization: 'Bearer ' + access_token
            },
            user_id : user,
            password : password.password
            })
            .then(function (response) {
            if(response.data.status == "failed")
            {
                toast.error('Failed ! Try Again');
                setIsSubmitting(false); // Re-enable button
            }
            else
            {
                toast.success('Password Updated');
                setIsSubmitting(false); // Re-enable button
                setPassword({ password : '',confirm_password : '' });
                setModal(false)
            }
            })
            .catch(function (error) {
            console.log(error);
            toast.error('Failed ! Try Again');
            });
        }
        else
        {
            toast.error('Confirm password not match');
        }
    }
}
 let change_password =()=>{
    setModal(true)
 }
 let edit_user =()=>{
    setModal2(true)
 }
 const handleSubmit2 = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('mobile', formData.mobile);
    data.append('photo', formData.photo);
    data.append('id', user);

    try {
        setIsSubmitting(true);
        const response = await axios.post(API_BASE_URL+'base/update_profile', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + access_token
            }
        });
        // console.log('Success:', response.data);
        toast.success('Profile Updated');
        setUserdata(response.data.data);
        setIsSubmitting(false);
        setModal2(false)
    } catch (error) {
        console.error('Error:', error);
    }
};
    //update  end
  return (
    <>
     <ToastContainer />
     {/* Main Wrapper */}
    <div className="main-wrapper">
     <Header />
     {/* Page Wrapper */}
      <div className="page-wrapper">
        {/* Password Modal */}
      <div >
        <div style={{ display: modalTriggered ? 'block' : 'none',position: 'absolute', zIndex: '999', width: '100%' }}>
          <div className="modal-dialog" style={{marginTop:'20px'}}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Change Password</h5>
              </div>
              <form onSubmit={handleSubmit}>
              <div className="modal-body">
                  <label htmlFor="name">New Password</label>
                  <input type="password" name="password"   className='form-control'  onChange={getValue} required/>
                  <label htmlFor="Date">Confirm Password</label>
                  <input type="password" name="confirm_password"   className='form-control'  onChange={getValue}  required/>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={close_modal}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>{isSubmitting?'Updating..':'Change Password'}
                </button>
              </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      {/* End Password modal */}
       {/* Edit Modal */}
       <div >
        <div style={{ display: modalTriggered2 ? 'block' : 'none',position: 'absolute', zIndex: '999', width: '100%' }}>
          <div className="modal-dialog" style={{marginTop:'20px'}}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Profile</h5>
              </div>
              <form onSubmit={handleSubmit2}>
              <div className="modal-body">
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name"   className='form-control' value={formData.name}  onChange={getValue2} required/>
                  <label htmlFor="Email">Email</label>
                  <input type="email" name="email"   className='form-control' value={userdata.email}   onChange={getValue2}  readonly/>
                  <label htmlFor="Mobile">Mobile</label>
                  <input type="number" name="mobile"   className='form-control'  value={formData.mobile}  onChange={getValue2}  />
                  <label htmlFor="photo">Photo</label>
                  <input type="file" name="photo"   className='form-control'  onChange={getValue2}  />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={close_modal2}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>{isSubmitting?'Updating..':'Update'}
                </button>
              </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      {/* End Edit modal */}
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">
                  <Link to={'/'}>Dashboard </Link>/ 
                  <span className="breadcrumb-item active">Profile</span>
                </h3>
              </div>
            </div>
          </div>
          {/* /Page Header */}
        <div className="profile-card">
        <div className="avatar2"><img src={userdata.photo_url} /></div>
        <div className="salutation">
            <div className="name">{userdata.name}</div>
            <div className="designation" ><i className='fa fa-envelope'></i> {userdata.email}</div>
            <div className="designation" ><i className='fa fa-phone'></i> {userdata.mobile}</div>
        </div>
        <div className="options">
            <button className="btn btn-sm" onClick={change_password}><img src={process.env.PUBLIC_URL + '/assets/img/password.png'} alt className="btn-img" /><span>Change Password</span></button>
            <button className="edit-profile"  onClick={edit_user}><img src={process.env.PUBLIC_URL + '/assets/img/edit-profile.png'} alt className="btn-img" /><span>Edit Profile</span></button>
        </div>
        </div>

    
        </div>
        {/* /Page Content */}
      </div>
      {/* /Page Wrapper */}
    </div>
    {/* /Main Wrapper */}

    <Footer page="profile"/>

    </>
  );
}

export default Profile;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// HOC for checking authentication
const withAuth = (WrappedComponent) => {
  const WithAuth = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
      if (!isLoggedIn) {
        // Redirect to login page if not logged in
        navigate('/login');
      }
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };

  return WithAuth;
};

export default withAuth;

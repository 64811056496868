import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../inc/Header';
import Footer from '../inc/Footer';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../data/constants';
import axios from 'axios';


export default function Todo() {
  const [tododata, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addtaskclass, setAddtaskclass] = useState(false);
  const [formTodo, setFormData] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission status
  const [modalTriggered, setModal] = useState(false); 
  const [editTodo, setEditData] = useState({task:'',id:'',index : ''});
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(value);
  };
  let user = sessionStorage.getItem('user');
  let access_token = sessionStorage.getItem('access_token');

  //Use STate For Fetch data
  useEffect(() => {
    axios.get(API_BASE_URL+'base/todolist', {
      params: {
        user_id: user
      },
      headers: {
        Authorization: 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response);
      setData(response.data);
      setIsLoading(false);
    })
    .catch(function (error) {
      console.log(error);
      setIsLoading(false);
    })
    .finally(function () {
      // always executed
      setIsLoading(false);
    });
  }, [access_token, user]);
  //End UseState

  if (isLoading) {
    return  <div className="loader"></div>;
  }
  
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // addTask
  let addTask = ()=>{
    setAddtaskclass(!addtaskclass)
  }
  //End

  //insert Todo Start
  let insertTodo = ()=>{
    if(formTodo == "")
    {
      toast.error('Todo Required');
      return false
    }
    setIsSubmitting(true); // Disable button
    axios.post(API_BASE_URL+'base/add_todo', {
      headers: {
        Authorization: 'Bearer ' + access_token
      },
      todo: formTodo,
      user_id : user
    })
    .then(function (response) {
      console.log(response.data);
      if(response.data.status == "failed")
      {
        toast.error('Failed ! Try Again');
        setIsSubmitting(false); // Re-enable button
      }
      else
      {
        toast.success('Todo Added');
        setIsSubmitting(false); // Re-enable button
        setData([...tododata, response.data.data]) // add row in state data
        setFormData('');
        console.log(tododata)

      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error('Failed ! Try Again');
    });
  }
  //insert todo end


  //delete todo start
  let delete_todo = (id,index) =>{
      axios.get(API_BASE_URL+'base/delete_todo', {
        params: {
          id: id
        },
        headers: {
          Authorization: 'Bearer ' + access_token
        }
      })
      .then(function (response) {
        if(response.data.status == "success")
        {
          let after_filter = tododata.filter((v,i) => i != index);
          setData(after_filter); 
          toast('Todo Deleted!');
        }
        else{
          toast('Failed');
        }
      })
      .catch(function (error) {
        console.log(error);
        toast('Failed');
      });
      
  }
  //end delete

  //status update todo
  let complete_todo = (id,status,index) =>{ 
    axios.get(API_BASE_URL+'base/complete_todo', {
      params: {
        id: id,
        status : (status == 0)?1:0
      },
      headers: {
        Authorization: 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      if(response.data.status == "success")
      {
        const newArray = [...tododata]; // Create a copy of the array
        newArray[index] = response.data.data;  // Update the value at the specified index
        setData(newArray);          // Update the state with the new array
        console.log(tododata[index]);
        // toast('Todo Updated!');
      }
      else{
        toast('Failed');
      }
    })
    .catch(function (error) {
      console.log(error);
      toast('Failed');
    });
    
    
  }
  let edit_todo = (id,task,index) =>{
    let oldData = {task : task, id : id ,index : index}
    setEditData(oldData);
    setModal(true)
  }
  let close_modal =() =>{
    setModal(false)
  }
  let getValue = (event) =>{
    let oldData = {...editTodo}
    console.log(oldData)
    let inputName = event.target.name;
    let inputValue = event.target.value;
    oldData[inputName] = inputValue;
    setEditData(oldData);
  }
  
  //update Todo Start
  let updateTask = ()=>{
    if(editTodo.task == "")
    {
      toast.error('Todo Required');
      return false
    }
    setIsSubmitting(true); // Disable button
    axios.post(API_BASE_URL+'base/update_todo', {
      headers: {
        Authorization: 'Bearer ' + access_token
      },
      todo: editTodo.task,
      id : editTodo.id
    })
    .then(function (response) {
      console.log(response.data);
      if(response.data.status == "failed")
      {
        toast.error('Failed ! Try Again');
        setIsSubmitting(false); // Re-enable button
      }
      else
      {
        toast.success('Todo Added');
        setIsSubmitting(false); // Re-enable button
        const newArray = [...tododata]; // Create a copy of the array
        newArray[editTodo.index].task =  editTodo.task;  // Update the value at the specified index
        setData(newArray);          // Update the state with the new array
        setEditData({task : '',id:'',index : ''});
        setModal(false)
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error('Failed ! Try Again');
    });
  }
  //update todo end
  return (
    <>
     <ToastContainer />
      <Header />
   

      <div className="page-wrapper">
      <div >
        <div style={{ display: modalTriggered ? 'block' : 'none',position: 'absolute', zIndex: '999', width: '100%' }}>
          <div className="modal-dialog" style={{marginTop:'20px'}}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Task</h5>
              </div>
              <div className="modal-body">
                <label>Task</label>
                <input type="text" name='task' className='form-control' value={editTodo.task} onChange={getValue}  />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={close_modal}
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary" onClick={updateTask} disabled={isSubmitting}>{isSubmitting?'Updating..':'Update'}
                  
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">
                  <Link to={'/'}>Dashboard </Link>/ 
                  <span className="breadcrumb-item active">Todo List</span>
                  <button className='add-task-btn btn btn-white btn-info btn-sm float-right'  onClick={addTask}>Add Task</button>
                </h3>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="chat-box">
            <div className="task-wrapper">
              <div className="task-list-container">
                <div className="task-list-body">
                <div className="task-list-footer">
                  <div className={`new-task-wrapper ${addtaskclass?'visible' :''}`}>
                    <form>
                    <textarea id="new-task" name="task" required placeholder="Enter new task here. . ." defaultValue={""}   onChange={handleChange}  value={formTodo}/>
                    <span className="error-message hidden">You need to enter a task first</span>
                    <span className="add-new-task-btn btn" id="add-task" onClick={insertTodo}  disabled={isSubmitting}>{isSubmitting?'Loading..':'Add Task'}</span>
                    <span className="btn" id="close-task-panel"  onClick={addTask}>Close</span>
                    </form>
                  </div>
                </div>
                  <ul id="task-list">
                    {tododata.map((task, i) => (
                      <li className={`task ${(task.status == 1) ? 'completed' : ''}`} key={i}>
                        <div className="task-container">
                          <span className="task-action-btn task-check">
                            <span className="action-circle large complete-btn" title="Mark Complete" onClick={() => complete_todo(task.id,task.status,i)}>
                              <i className="material-icons">check</i>
                            </span>
                          </span>
                          <span className="task-label" contentEditable="false">{task.task}</span>
                          <span className="task-action-btn task-btn-right">
                            {/* <span className="action-circle large" title="Assign">
                              <i className="material-icons">person_add</i>
                            </span> */}
                            <span className="action-circle large delete-btn" title="Delete Task" onClick={() => {if(window.confirm('Are you sure ?')){delete_todo(task.id,i)};}}>
                              <i className="material-icons">delete</i>
                            </span>&nbsp;
                            <span className="action-circle large delete-btn" title="Delete Task" onClick={() => edit_todo(task.id,task.task,i)}>
                              <i className="material-icons">edit</i>
                            </span>
                          </span>
                        </div>
                      </li>
                    ))}
                    {(tododata.length == 0)?
                      <h1 className='text-center'>No Data Available...</h1>
                    : ""}
                  </ul>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
     
      
      <Footer  page="todolist" />
      
    </>
  );
}

import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../data/constants';

export default function Login() {
    const [isSubmitting, setIsSubmitting] = useState(false); // Track submission status

    const [formData, setFormData] = useState({
        email: '',
        password: ''
      });
    let [formError, setFormError] = useState({
        email: '',
        password: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState, //...prevState uses the spread operator to copy all properties from the previous state into the new state object.
          [name]: value //[name]: value uses computed property names to dynamically set the property with the key equal to the name variable, and the value equal to the value variable.
        }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
            // sessionStorage.setItem('isLoggedIn', true);
            // sessionStorage.setItem('access_token', "asdhajsk;");
            // sessionStorage.setItem('user', 2);
            // window.location.href = '/';
        if(formData.email == "") 
            setFormError({email : 'This field is required' })
        else
            setFormError({email : '' })
        if(formData.password == "") 
            setFormError({password : 'This field is required' })
        else
            setFormError({password : '' })
       
        if(formData.email == "" || formData.password == "") 
        {
            return false
        }
        setIsSubmitting(true); // Disable button
        // Send login request to backend
        axios.post(API_BASE_URL+'base/check_login', {
          email: formData.email,
          password: formData.password
        })
        .then(function (response) {
          console.log(response.data);
          if(response.data.status == "failed")
          {
            toast.error('Invalid Login Credential');
            setIsSubmitting(false); // Re-enable button
          }
          else
          {
            toast.success('Login Success');
            setFormData({email : '',password :'' })
            sessionStorage.setItem('isLoggedIn', true);
              sessionStorage.setItem('access_token', response.data.access_token);
              sessionStorage.setItem('user', response.data.user.id);
              window.location.href = '/';
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error('Login Failed');
          
        });
        
      };
  return (
    <>
        <ToastContainer />

        {/* Main Wrapper */}
        <div className="main-wrapper">
        <div className="account-content">
            <div className="container">
            <br></br>
            <br></br>
            <br></br>
            <div className="account-box">
                <div className="account-wrapper">
                <h3 className="account-title">Login</h3>
                <p className="account-subtitle">Access to our dashboard</p>
                {/* Account Form */}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                    <label>Email Address</label>
                    <input className="form-control" type="email" id="email" name="email" value={formData.email} onChange={handleChange} required/>
                        <p style={{color:'red'}}>{formError.email}</p>
                    </div>
                    <div className="form-group">
                    <div className="row">
                        <div className="col">
                        <label>Password</label>
                        </div>
                        <div className="col-auto">
                        <a className="text-muted" href="forgot-password.html">
                            Forgot password?
                        </a>
                        </div>
                    </div>
                    <input className="form-control" type="password"  id="password" name="password" value={formData.password} onChange={handleChange}  required/>
                        <p style={{color:'red'}} >{formError.password}</p>
                    </div>
                    <div className="form-group text-center">
                    <button disabled={isSubmitting} className="btn btn-primary account-btn" type="submit">{isSubmitting?'Authenticating':'Login'}</button>
                    </div>
                    <div className="account-footer">
                    <p>Don't have an account yet? <a href="register.html">Register</a></p>
                    </div>
                </form>
                {/* /Account Form */}
                </div>
            </div>
            </div>
        </div>
        </div>
        {/* /Main Wrapper */}

		</>
  )
}

// src/EventCalendar.js

import React, { useState,useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { API_BASE_URL } from './data/constants';
import axios from 'axios';
import swal from 'sweetalert';


const localizer = momentLocalizer(moment);
const EventCalendar = () => {
//Get All Events
const [all_events, setData] = useState([]);
const [events, setEvents] = useState([]);
let user = sessionStorage.getItem('user');
let access_token = sessionStorage.getItem('access_token');
useEffect(() => {
    axios.get(API_BASE_URL+'base/events', {
      params: {
        user_id: user
      },
      headers: {
        Authorization: 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response);
      setData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  }, [access_token, user]);


//Get All EventsEND

//Calendar STart
const convertDateToTargetYear = (dateString, targetYear) => {
    const date = new Date(dateString);
    date.setFullYear(targetYear);
    return date;
};

  useEffect(() => {
    const targetYear =  new Date().getFullYear();
    const eventdata = all_events.map((row) => ({
      title: row.name+ "-"+row.type,
      id: row.id,
      start: convertDateToTargetYear(row.date, targetYear),
      end: convertDateToTargetYear(row.date, targetYear),
    }));
    setEvents(eventdata);
  }, [all_events]);
  const handleSelectSlot = ({ start, end }) => {
    const title = window.prompt('New Event name');
    if (title) {
      setEvents([...events, { start, end, title }]);
    }
  };

  const handleSelectEvent = (event) => {
    swal(event.title);
  };
//Calendar End

  return (
    <div style={{ height: '500px' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        style={{ height: '100%' }}
      />
    </div>
  );
};

export default EventCalendar;
